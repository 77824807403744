<template>
  <div>
    <p>Téléchargement en cours... Si le téléchargement ne commence pas automatiquement, <a :href="pdfURL" download>cliquez ici</a>.</p>
  </div>
</template>

<script>
export default {
  name: "DownloadPDF",
  data() {
    return {
      // L'URL correcte pour accéder au fichier PDF
      pdfURL: "/pdf/TableauDonnées.pdf", // Le fichier est accessible à partir de /public/pdf
    };
  },
  mounted() {
    this.downloadPDF();
  },
  methods: {
    downloadPDF() {
      // Crée un élément <a> pour déclencher le téléchargement
      const link = document.createElement("a");
      link.href = this.pdfURL;
      link.download = "TableauDonnées.pdf"; // Nom du fichier téléchargé
      link.click();
    },
  },
};
</script>

<style scoped>
p {
  text-align: center;
  margin-top: 20px;
}
</style>
